module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/opt/build/repo/src/components/layout/layout.jsx"},"remarkPlugins":[null,null],"rehypePlugins":[[null,{"strict":false}]],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/build-plugins/remark-absolute-image-src.js"},{"resolve":"@josephuspaye/gatsby-remark-graphviz","options":{"optimize":true,"wrapperTag":"div","wrapperClass":"remark-graphviz-graph","figureClass":"remark-graphviz-figure","figcaptionClass":"remark-graphviz-figcaption","firstCommentIsCaption":true,"generateAriaDescription":true}},"gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-autolink-headers","options":{"maintainCase":false,"removeAccents":true}}],"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NUbook","short_name":"NUbook","start_url":"/NUbook","background_color":"#24292E","theme_color":"#F9A50D","display":"minimal-ui","icon":"src/images/nubots-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"80a0ce52493e78967324e32c08a979a0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
